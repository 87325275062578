import { ShortcutKeyEvent } from '#core/constant'

export const useDashboardSidebar = () => {
  const isExpanded = useLocalStorage('is-sidebar-expanded', true)

  const toggleSidebar = (open?: boolean) => {
    isExpanded.value = open !== undefined ? open : !isExpanded.value
  }

  useShortcutKeys([{
    shortcutKeyEvent: ShortcutKeyEvent.TOGGLE_EXPAND_SIDEBAR,
    handle: toggleSidebar
  }])

  return {
    isExpanded,
    toggleSidebar,
  }
}
